<template>
  <render ref="content" />
</template>

<script setup lang="ts">
import { ref, computed, useSlots } from 'vue'
import { cartChangeResource } from '@/services/cart.service'
import { useShowModal } from '@/composables/useModalOpener'
import { useSimpleErrorsGetter } from '@/components/Forms/FormMixin'
import { useCartStore } from '@/store/cart'
import useGlobal from '@/composables/useGlobal'

const props = defineProps({
  itemId: {
    type: [String, Number],
  },
  type: {
    type: String,
  },
})

const slots = useSlots() as any

const { $t } = useGlobal()

const cartStore = useCartStore()

const isLoad = ref(false)

const isInCart = computed(() => {
  return cartStore.cartInfo.groups.some((el: any) => {
    if (el.base.element.id.toString() === props.itemId?.toString()) {
      return true
    }

    return false
  })
})

const addToCart = () => {
  if (isLoad.value) return

  isLoad.value = true

  const formdata = {
    entities: [{
      element: {
        type: props.type,
        id: props.itemId?.toString(),
      }
    }]
  }
  cartChangeResource.execute({}, formdata).then(() => {
    cartStore.getCartData()

    const message = {
      title: $t('Курс додано до кошика'),
    }
    useShowModal(message, { timeout: 1500 })
  }).catch(async (e: object) => {
    const parsed = await useSimpleErrorsGetter(e)
    const { entities } = parsed

    if (entities && entities[0]) {
      const title = entities[0].nonFieldErrors[0].message

      const message = {
        title,
        text: $t('Зверніться до адміністратора сайту для отримання детальної інформації'),
      }
      useShowModal(message)
    } else {
      const message = {
        title: $t('Виникла непередбачена помилка'),
        text: $t('Зверніться до адміністратора сайту для отримання детальної інформації'),
      }
      useShowModal(message)
    }
  }).finally(() => {
    isLoad.value = false
  })
}

const render = () => slots.default({
  addToCart,
  isInCart: isInCart.value,
})
</script>
